@use 'styles/base/mixins';
@use 'styles/base/variables';

.fullScreenIframe {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;

  iframe + .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    background: variables.$gb_white;

    @include mixins.screen-sm {
      top: 1.5rem;
      right: 1.5rem;
    }

    > button {
      margin: 0;
    }
  }
}
