@use 'functions';
@use 'variables';
@use 'sass:map';
@use 'sass:math';
@use '../vendor/icons-mixin';

@mixin breakpoint($breakpoint) {
  @media (min-width: map.get(variables.$breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: variables.$gb_breakpointLG) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: variables.$gb_breakpointMD) {
    @content;
  }
}

@mixin screen-max-md {
  @media (max-width: variables.$gb_breakpointMD) {
    @content;
  }
}

@mixin only-screen-md {
  @media (min-width: variables.$gb_breakpointSM) and (max-width: variables.$gb_breakpointMD) {
    @content;
  }
}

@mixin screen-sm {
  @media (min-width: variables.$gb_breakpointSM) {
    @content;
  }
}

@mixin only-screen-sm {
  @media (min-width: variables.$gb_breakpointXS) and (max-width: variables.$gb_breakpointSM) {
    @content;
  }
}

@mixin below-screen-sm {
  @media (max-width: variables.$gb_breakpointSM) {
    @content;
  }
}

@mixin screen-xs {
  @media (min-width: variables.$gb_breakpointXS) {
    @content;
  }
}

@mixin below-screen-xs {
  @media (max-width: variables.$gb_breakpointXS) {
    @content;
  }
}

@mixin set-responsive-font-sizes($xs, $sm, $md) {
  font-size: functions.calc-pixel($xs);
  line-height: functions.calc-line-height($xs);

  @include screen-sm {
    font-size: functions.calc-pixel($sm);
    line-height: functions.calc-line-height($sm);
  }

  @include screen-md {
    font-size: functions.calc-pixel($md);
    line-height: functions.calc-line-height($md);
  }
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

/**
 * Example usage:
 *
 *   .button:before {
 *      @include mixins.web20-icon("arrow-right");
 *   }
 */

@mixin web20-icon($icon-name) {
  @include icons-mixin.web20-icon-mixin($icon-name);
}

/**
 * Example usage:
 *
 *   .button:before {
 *      @include mixins.web20-icon-content("arrow-right");
 *   }
 */

@mixin web20-icon-content($icon-name) {
  @include icons-mixin.web20-icon-content-mixin($icon-name);
}

/**
 * Example usage:
 *
 *   h1 {
 *      @include hyphens();
 *   }
 */
@mixin hyphens() {
  -ms-word-break: normal;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/**
 * Example usage:
 *
 *   h1 {
 *      @include mixins.word-wrap();
 *   }
 */
@mixin word-wrap() {
  @include hyphens();
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/**
 * Example usage:
 *
 *  div {
 *    @include content-columns(3);
 *  }
 */
@mixin content-columns($columnCount) {
  -webkit-column-count: $columnCount;
  -moz-column-count: $columnCount;
  column-count: $columnCount;
}

/**
 * Example usage:
 *  $value: avoid | auto | initial | inherit
 *
 *  div {
 *     @include column-break-inside(avoid);
 *  }
 */
@mixin column-break-inside($value) {
  -webkit-column-break-inside: $value;
  page-break-inside: $value;
  break-inside: $value;
}

/**
 * Example usage:
 *
 *  div {
 *     @include aspect-ratio(16, 9);
 *  }
 */
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: math.div($height, $width) * 100%;
  }

  > .image-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/**
 * Example usage: flex-flow
 *
 *  div {
 *     @include flex-flow();
 *  }
 */
@mixin flex-flow() {
  flex-flow: row wrap;
  -ms-flex-flow: row wrap;
}

@mixin playIcon($sizeMobile, $sizeDesktop, $hasHover: false, $zindex: inherit) {
  $mobileIconSize: math.div($sizeMobile, 2) - 5;
  $desktopIconSize: math.div($sizeDesktop, 2) - 10;

  &::after {
    @include web20-icon('play');
    @if $hasHover {
      background: rgba(255, 255, 255, 0.3);
    } @else {
      background: rgba(255, 255, 255, 0.5);
    }
    border-radius: 50%;
    border: 1px solid variables.$gb_black;
    display: block;
    font-size: functions.calc-pixel($mobileIconSize);
    height: functions.calc-pixel($sizeMobile);
    left: 50%;
    line-height: functions.calc-pixel($sizeMobile);
    position: absolute;
    text-align: center;
    text-indent: 3px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: functions.calc-pixel($sizeMobile);
    z-index: $zindex;
    @include screen-sm {
      width: functions.calc-pixel($sizeDesktop);
      height: functions.calc-pixel($sizeDesktop);
      font-size: functions.calc-pixel($desktopIconSize);
      line-height: functions.calc-pixel($sizeDesktop);
      text-indent: 4px;
    }
  }
  @if $hasHover {
    &:hover {
      &::after {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

/**
 * Example usage: line-clamp
 * maximum number of lines
 * characters are cut off and end with three dots ...
 *
 *  div {
 *     @include mixins.line-clamp($integer);
 *  }
 */
@mixin line-clamp($integer) {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $integer;
  overflow: hidden;
}

/**
 * Example usage: bullet-points-spacing
 *
 *  div {
 *     @include mixins.bullet-points-spacing();
 *  }
 */
@mixin bullet-points-spacing() {
  ol,
  ul {
    padding-left: 1.5rem;

    li {
      padding-bottom: 0;
    }

    ol,
    ul {
      margin-top: 0px;
    }
  }
}

@mixin typography($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}

@mixin typography-body-1() {
  @include typography(variables.$font-size-body-1, variables.$line-height-body-1);
}

@mixin typography-body-2() {
  @include typography(variables.$font-size-body-2, variables.$line-height-body-2);
}

@mixin typography-label-1() {
  @include typography(variables.$font-size-label-1, variables.$line-height-label-1);
}

@mixin typography-label-2() {
  @include typography(variables.$font-size-label-2, variables.$line-height-label-2);
}

@mixin typography-h-1() {
  @include typography(variables.$font-size-h-5, variables.$line-height-h-5);

  @include screen-sm {
    @include typography(variables.$font-size-h-3, variables.$line-height-h-3);
  }

  @include screen-md {
    @include typography(variables.$font-size-h-2, variables.$line-height-h-2);
  }

  @include screen-lg {
    @include typography(variables.$font-size-h-1, variables.$line-height-h-1);
  }
}

@mixin typography-h-2() {
  @include typography(variables.$font-size-h-6, variables.$line-height-h-6);

  @include screen-sm {
    @include typography(variables.$font-size-h-5, variables.$line-height-h-5);
  }

  @include screen-md {
    @include typography(variables.$font-size-h-4, variables.$line-height-h-4);
  }

  @include screen-lg {
    @include typography(variables.$font-size-h-3, variables.$line-height-h-3);
  }
}

/*
  line clamp classes

  Note:
  Each breakpoint needs a separate loop to have a higher selector specificity than the previous one.
  This is achieved by calling the mixin for each breakpoint.
*/
@mixin line-clamps-for-breakpoint($breakpoint) {
  @for $i from 1 through 5 {
    .line-clamp-#{$breakpoint}-#{$i} {
      @media (min-width: #{$breakpoint}px) {
        @include line-clamp($i);
      }
    }
  }
}
