@use 'styles/base/variables';
@use 'styles/base/mixins';

.wrapper {
  margin: 4rem 0;
}

.errorWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed variables.$gb_grey_400;
}

.locatorContainer {
  :global(.xy) & {
    @include mixins.screen-md {
      max-width: 90rem;
    }
  }
}
