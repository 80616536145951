/* stylelint-disable */
@use '../base/variables';

$icon-prefix: 'icon-';
$icons-map: (
  'icon-3d-bath-planner': '\f101',
  'icon-account': '\f102',
  'icon-alert': '\f103',
  'icon-anchor': '\f104',
  'icon-arrow-link-bold-down': '\f105',
  'icon-arrow-link-bold-left': '\f106',
  'icon-arrow-link-bold-right': '\f107',
  'icon-arrow-link-bold-up': '\f108',
  'icon-arrow-link-light-down': '\f109',
  'icon-arrow-link-light-left': '\f10a',
  'icon-arrow-link-light-right': '\f10b',
  'icon-arrow-link-light-up': '\f10c',
  'icon-attachment': '\f10d',
  'icon-autofilled': '\f10e',
  'icon-available-seats': '\f10f',
  'icon-b2b': '\f110',
  'icon-b2c-bigger': '\f111',
  'icon-b2c': '\f112',
  'icon-back': '\f113',
  'icon-bathroom-products': '\f114',
  'icon-breadcrumb-arrow': '\f115',
  'icon-breadcrumb-home': '\f116',
  'icon-bullet': '\f117',
  'icon-calendar': '\f118',
  'icon-campus-cost': '\f119',
  'icon-campus-mail': '\f11a',
  'icon-campus-phone': '\f11b',
  'icon-campus-pin': '\f11c',
  'icon-chat': '\f11d',
  'icon-check': '\f11e',
  'icon-chevron-large-down': '\f11f',
  'icon-chevron-large-left': '\f120',
  'icon-chevron-large-right': '\f121',
  'icon-chevron-large-up': '\f122',
  'icon-chevron-small-down': '\f123',
  'icon-chevron-small-left': '\f124',
  'icon-chevron-small-right': '\f125',
  'icon-chevron-small-up': '\f126',
  'icon-close': '\f127',
  'icon-collect': '\f128',
  'icon-compare': '\f129',
  'icon-contact-form': '\f12a',
  'icon-cost-person': '\f12b',
  'icon-course-date': '\f12c',
  'icon-disk': '\f12d',
  'icon-download-book': '\f12e',
  'icon-download-excel': '\f12f',
  'icon-download-neutral': '\f130',
  'icon-download-pdf': '\f131',
  'icon-download-zip': '\f132',
  'icon-download': '\f133',
  'icon-duration': '\f134',
  'icon-elearning': '\f135',
  'icon-event': '\f136',
  'icon-external-link': '\f137',
  'icon-facebook': '\f138',
  'icon-file-with-type': '\f139',
  'icon-file': '\f13a',
  'icon-flipbook': '\f13b',
  'icon-functions': '\f13c',
  'icon-gas': '\f13d',
  'icon-gdds-check': '\f13e',
  'icon-gdds-close': '\f13f',
  'icon-gdds-document': '\f140',
  'icon-gdds-download': '\f141',
  'icon-gdds-home': '\f142',
  'icon-globe': '\f143',
  'icon-hard-drive': '\f144',
  'icon-heating': '\f145',
  'icon-hover_icon-hover-image': '\f146',
  'icon-industry': '\f147',
  'icon-info-circle-full': '\f148',
  'icon-info-circle-outline': '\f149',
  'icon-information': '\f14a',
  'icon-inspiration': '\f14b',
  'icon-inspirator': '\f14c',
  'icon-installation-systems': '\f14d',
  'icon-language': '\f14e',
  'icon-lens-zoom-in': '\f14f',
  'icon-lens-zoom-out': '\f150',
  'icon-linkedin': '\f151',
  'icon-location': '\f152',
  'icon-locator-pos': '\f153',
  'icon-marker-hotel-pin': '\f154',
  'icon-marker-pin': '\f155',
  'icon-menu-burger': '\f156',
  'icon-minus': '\f157',
  'icon-more-active': '\f158',
  'icon-more': '\f159',
  'icon-nav-bathroom-systems': '\f15a',
  'icon-nav-collections': '\f15b',
  'icon-nav-drainage-systems': '\f15c',
  'icon-nav-installation': '\f15d',
  'icon-nav-supply-systems': '\f15e',
  'icon-nav-tools': '\f15f',
  'icon-new': '\f160',
  'icon-newsletter': '\f161',
  'icon-online-catalogue': '\f162',
  'icon-open-book': '\f163',
  'icon-options': '\f164',
  'icon-pencil-filled': '\f165',
  'icon-picture': '\f166',
  'icon-pinterest': '\f167',
  'icon-play': '\f168',
  'icon-plus': '\f169',
  'icon-printer': '\f16a',
  'icon-reload': '\f16b',
  'icon-search-document': '\f16c',
  'icon-search': '\f16d',
  'icon-seminar': '\f16e',
  'icon-service-chat': '\f16f',
  'icon-service-clock': '\f170',
  'icon-service-fax': '\f171',
  'icon-service-mail': '\f172',
  'icon-service-phone': '\f173',
  'icon-service-pin-outline': '\f174',
  'icon-share': '\f175',
  'icon-shipbuilding': '\f176',
  'icon-shoppingcart': '\f177',
  'icon-sprinkler': '\f178',
  'icon-target-group': '\f179',
  'icon-thumb-down': '\f17a',
  'icon-thumb-up': '\f17b',
  'icon-tools': '\f17c',
  'icon-trashcan': '\f17d',
  'icon-twitter': '\f17e',
  'icon-underfloor-heating': '\f17f',
  'icon-user': '\f180',
  'icon-watch-list-add': '\f181',
  'icon-watch-list-added': '\f182',
  'icon-watchlist': '\f183',
  'icon-water': '\f184',
  'icon-webcast': '\f185',
  'icon-webinar': '\f186',
  'icon-xing': '\f187',
);

@mixin web20-icon-content-mixin($icon, $icons: $icons-map) {
  content: '#{map-get($icons, $icon-prefix + $icon)}';
}

@mixin web20-icon-mixin($icon, $icons: $icons-map) {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-family: 'geberitPlatfomWeb20IconFont' !important;
  font-style: normal;
  font-weight: normal !important;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  content: '#{map-get($icons, $icon-prefix + $icon)}';
}
